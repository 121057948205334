import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Footer from "../Common/Footer";

class Faq extends React.Component {
  submitHandler = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>FAQ</h1>
            <span>Frequently asked Questions</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>FAQ</li>
            </ul>
          </div>
        </div>

        <section className="faq-area ptb-120">
          <div className="container">
            <div className="faq-accordion">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Why register?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      <ol type="circle">
                        <li>Certification</li>
                        <li>Cash Prizes</li>
                        <li>Networking</li>
                        <li>Industry Exposure</li>
                        <li>Cool Merchandise</li>
                      </ol>
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What are the dates for Nirmitee 2023?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>16 th and 17 th March 2023.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Faq;
