import React from "react";
import lax from "lax.js";
import LaxButton from "../Shared/LaxButton";

class About extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section className="about-area-two ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <span>Join The Event</span>
                <h2>
                  Here’s something about <b>NIRMITEE</b>
                </h2>

                <p>
                  Nirmitee is a National Level symposium for all the civil
                  enthusiasts out there. It has been the legacy of the School of
                  Civil Engineering ever since the year 2000. This year, the
                  spectacular event embarks upon its 23rd edition, intending to
                  set new benchmarks every step of the way.
                </p>

                <p>
                  Nirmitee conducts competitions oriented toward upskilling
                  budding Civil Engineers and creates a platform for interaction
                  with profound & rooted members of the ever-developing
                  construction industry. The competitions bridge the gap between
                  industry & academia and strive to result in the most
                  innovative solutions for modern day problem statements.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={require("../../assets/images/about3.jpg")}
                  className="about-img1"
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
