import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeDefault from "./components/pages/HomeDefault";
import Navigation from "./components/Navigation/Navigation";
import AboutTwo from "./components/pages/AboutTwo";
import SpeakersThree from "./components/pages/SpeakersThree";
import NotFound from "./components/pages/NotFound";
import Faq from "./components/pages/Faq";
import Contact from "./components/pages/Contact";
import BlogOne from "./components/pages/BlogOne";
import Sponsor from "./components/pages/Sponsor";
import Workshop from "./components/pages/Workshop";

// Conditionally render Navigation
const renderNavigation = () => {
    if (!(window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/coming-soon' || window.location.pathname === '/error-404')){
        return <Navigation />;
    }
}

const AppRouter = () => {
    return (
        <Router>
            {renderNavigation()}
            <Switch>
                <Route path="/" exact component={HomeDefault} />
                <Route path="/about-2" exact component={AboutTwo} />
                <Route path="/team" exact component={SpeakersThree} />
                <Route path="/faq" exact component={Faq} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/events" exact component={BlogOne} />
                <Route path="/workshops" exact component={Workshop} />
                <Route path="/sponsors" exact component={Sponsor} />
                <Route path="/error-404" exact component={NotFound} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default AppRouter;