import React from "react";
import { Link } from "react-router-dom";

class WhyUs extends React.Component {
  render() {
    return (
      <section className="why-choose-us">
        <div className="row m-0">
          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-headphone-alt-1"></i>
                    </div>
                    <h3>Journey So Far</h3>
                    <p>
                      Reflecting on the milestones and obstacles of the journey
                      so far, an unforgettable event filled with growth and
                      resilience.
                    </p>

                    <Link to="/about-3" className="btn btn-primary">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-network-tower"></i>
                    </div>
                    <h3>Participants</h3>
                    <p>
                      Exploring the diverse range of participants, each bringing
                      their unique perspective and energy to make the event a
                      memorable experience.
                    </p>

                    <Link to="/about-3" className="btn btn-primary">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-users-social"></i>
                    </div>
                    <h3>Networking</h3>
                    <p>
                      Networking opportunities abound, connecting like-minded
                      individuals and fostering meaningful relationships at the
                      heart of the event.
                    </p>

                    <Link to="/about-3" className="btn btn-primary">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-wink-smile"></i>
                    </div>
                    <h3>Our Vision</h3>
                    <p>
                      Our vision inspires and drives the event, bringing
                      together a community of passionate individuals committed
                      to creating a better future.
                    </p>

                    <Link to="/about-3" className="btn btn-primary">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="slideshow">
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
        </ul>
      </section>
    );
  }
}

export default WhyUs;
