import React from "react";
import { Link } from "react-router-dom";


class Footer extends React.Component {

  linkedin = () =>{
    window.open("https://www.linkedin.com/company/mitwpunirmitee");
  }

  facebook =()=>{
    window.open(" https://www.facebook.com/mitwpunirmitee");
}

instagram =()=>{
    window.open("  https://www.instagram.com/mitwpunirmitee/?hl=en ");
}

youtube =()=>{
  window.open("   https://www.youtube.com/channel/UC-aSY-unwdIRmwpMfRoPjNA ");
}

  
  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Venue Location</h3>
                <span>
                  <i className="icofont-calendar"></i> March 2024
                </span>

                <p className="location">
                  <i className="icofont-google-map"></i> 
                    MIT World Peace University, Pune,Maharashtra
                </p>

                <Link to="/contact" className="contact-authority">
                  <i className="icofont-phone"></i> Contact Us
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-4">
              <div className="single-footer-widget"
                style={{ textAlign: "center" }}
              >
                <h3>Social Connection</h3>
                <p>
                  Stay updated about Nirmitee through our social media handles
                </p>

                <ul className="social-links">
                  <li>
                    <Link
                      to="#"
                      onClick={this.facebook}
                      className="facebook"
                    >
                      <i className="icofont-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={this.youtube}
                      className="twitter"
                    >
                      <i className="icofont-youtube"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={this.linkedin}
                      className="linkedin"
                    >
                      <i className="icofont-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={this.instagram}
                      className="instagram"
                    >
                      <i className="icofont-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="copyright-area">
                <ul>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  {/* <li>
                    <Link to="/faq">FAQ's</Link>
                  </li> */}
                  <li>
                    <Link to="/sponsors">Sponsors</Link>
                  </li>
                </ul>
                <p>
                  Copyright <i className="icofont-copyright"></i> 2024 Nirmitee.
                  All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
