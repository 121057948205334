import React from "react";
import { Link } from "react-router-dom";

class WhyUs extends React.Component {
  render() {
    return (
      <section className="why-choose-us-two">
        <div className="row m-0 h-100 align-items-center">
          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-img">
              <img
                src={require("../../assets/images/why-choose-img1.jpg")}
                alt="Why Us"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-content">
              <h3>Vision</h3>
              <p>
                To provide an opportunity for the accomplished and aspiring
                civil engineers to indulge, exchange ideas and foster new
                integrated approaches and build a strong network of determined
                professionals.
              </p>

              <span>01</span>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-img">
              <img
                src={require("../../assets/images/why-choose-img2.jpg")}
                alt="Why Us"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-content">
              <h3>Mission</h3>
              <p>
                “To aggrandize the changing methods in the construction industry
                brought about by the evolution of technology whilst preserving
                the cultural heritage of India in today’s digital era.
              </p>

              <span>02</span>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-img">
              <img
                src={require("../../assets/images/why-choose-img3.jpg")}
                alt="Why Us"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-content">
              <h3>Know our Theme</h3>
              <p>
                Here at Nirmitee, we strive to learn from the great architects
                of Ancient India, in the hope to follow their footsteps and use
                the present technology to achieve distinguished success in
                modern architecture, hence the theme <br/>
                <b>‘Sthapati to Modernity.’</b>
              </p>

              <span>03</span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WhyUs;
