import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import OwlCarousel from "react-owl-carousel3";
import PlatinumSponsors from "../LaxButton/PlatinumSponsors";
import GoldSponsors from "../LaxButton/GoldSponsors";
import Footer from "../Common/Footer";

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
};

const sponsers1 = [
  {
    img : "18"
  },

  {
    img : "19"
  },

  {
    img : "20"
  },

  {
    img : "21"
  },

  {
    img : "22"
  },

  {
    img : "23"
  },

  {
    img : "24"
  },

  {
    img : "25"
  }
];


class Sponsor extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>Our Sponsors</h1>
            <span>Check Who Makes This Event Possible!</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Sponsors</li>
            </ul>
          </div>
        </div>

        <section className="partner-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="partner-title platinum-sponsor">
                  <PlatinumSponsors />
                </div>
              </div>

              <OwlCarousel
                className="platinum-partner-slides owl-carousel owl-theme"
                {...options}
              >
                {sponsers1.map((sponser) => (
                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/${sponser.img}.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/${sponser.img}.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </OwlCarousel>

              <div className="col-lg-12">
                <div className="border"></div>
              </div>

              <OwlCarousel
                className="gold-partner-slides owl-carousel owl-theme"
                {...options}
              >
                <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/6.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/6.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/7.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/7.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/8.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/8.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/9.jpg`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/9.jpg`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/10.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/10.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/16.jpeg`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/16.jpeg`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
               
              </OwlCarousel>

              <div className="col-lg-12">
                <div className="border"></div>
              </div>

              <OwlCarousel
                className="gold-partner-slides owl-carousel owl-theme"
                {...options}
              >
                <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/2.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/2.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/3.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/3.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/4.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/4.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/5.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/5.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/13.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/13.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="partner-item">
                      <Link to="/sponsors" target="_blank">
                        <img
                          src={require(`../../assets/images/14.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                        <img
                          src={require(`../../assets/images/14.png`)}
                          alt="Partner Logo"
                          style={{
                            height: "100px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
               
              </OwlCarousel>

            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Sponsor;
