import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Common/Footer";


const events = [
  {
    name: "TechnoQuiz",
    desc: "An event that consists of logical and aptitude questions in the domain of civil engineering and allows participants to showcase their intellect.",
    img : "Technoquiz",
    register : 'linkedin',
    type: "Tech",
    Coordinator: "Aditya Hole",
    number : "9657282930"
  },

  {
    name: "Paper Presentation",
    desc: "Foundations of Engineering Technology are built on the grounds of innovation and it's always fruitful when the innovation is met with a great deal of accurate and factual research.",
    img : "Paper Presentation",
    type: "Tech",
    Coordinator: "Tanmay Kadu",
    number : "8507773611"
  },

  {
    name: "CV Cruits",
    desc: "We at NIRMITEE are delighted to present CV CRUITS, where you get to experience an extremely professional “Hire and Fire process.” Prepare yourself to face all the challenges that may stand your way in the near future!",
    img : "CV-Cruits",
    type: "Tech",
    Coordinator: "Tanmay Dhore",
    number : "9373467877"
  },

  {
    name: "Softcon",
    desc: "Step into the world of digital extravaganza! SOFTCON, an event which equips participants with real time problem statements and a platform to test their problem solving capabilities by utilization of SOFTWARE.",
    img : "Softcon",
    type: "Tech",
    Coordinator: "Abhishek Beede",
    number : "8310189604"
  },

  {
    name: "e-Tendering",
    desc: "Financial management is the heart and soul of any business. If you manage it well, it can all works out! Gear up your business skills with our event “E-tendering”. Manage and prepare an affordable tender by registering today.",
    img : "E-Tendering",
    type: "Tech",
    Coordinator: "Rohit patil",
    number : "7720012520"
  },

  {
    name: "Bob The Builder",
    desc: "The event describes the effects of earthquakes on structures, the importance of earthquake-resistant structures, and the revised guidelines regarding the earthquake -resistant design of the structure.",
    img : 'Bob the Builder',
    type : 'Tech',
    Coordinator: "Shahid Motiwale",
    number : "7741809180"
  },

  // {
  //   name: "Pitch Hunt",
  //   desc: "Suit up! And join the elite club of the 1% who think apart and change the global scene. Bring in ideas that have the potential to change our lives and benefit society at the same time. Be a part of PITCH-HUNT, a platform to showcase your ideas and business plans!",
  //   img : "Pitchunt",
  //   type: "Tech",
  //   price: "Rs. 100"
  // },

  // {
  //   name: "Scrap to Fab",
  //   desc: "Making helpful and beautiful things out of waste accessible at home, rather than tossing them is the best use for them. ‘Scrap to Fab’ essentially means to make something creative and alluring out of the material that is of no utilization in any case.",
  //   img : "Scrap to Fab",
  //   type: "Non-Tech",
  //   price: "Free"
  // },

  {
    name: "Treasure Hunt",
    desc: "Channel your inner Scooby Doo and be a part of the Treasure Hunt brought to you by Nirmitee.This event allows you to have fun whilst challenging your intellect.",
    img : "Treasure Hunt",
    type: "Non-Tech",
    Coordinator: "Samarth Relekar",
    number : "7391955955"
  },

  {
    name: "B’Plan In construction",
    desc: "It is a platform where participants can put their entrepreneurial skills to test. The basic motive is to generate a business plan and proposal value obtain funding by pitching to investors, banks and financial institutions This event aims to prepare participants for future Endeavours. PARTICIPANTS  CAN USE the JOHN  MULLINS   7  POINT   .....IDEAS  EVALUATION  FRAMEWORK....before submitting their  BUSINESS  PLAN....",
    img : "Business Plan",
    type: "Tech",
    Coordinator: "Hetul Suryawanshi",
    number : "9969532774"
  },

  {
    name: "R.Y.L.A",
    desc: "Have you been wondering how to get acknowledged and appreciated for your accomplishments? NIRMITEE 23’ brings to you the RYLA (Rotary Youth Leadership Award), a magnificent felicitous event that brings out the achiever and the LEADER in you",
    img : "RYLA",
    type: "Non-Tech",
    Coordinator: "Ashraf Mulani",
    number : "9765716628"
  },
  {
    name : "Box Cricket",
    desc : "Box cricket, an extracurricular activity introduced for the first time in Nimitee's 25-year history, is a unique form of cricket played indoors, typically within the confines of a net. This indoor variation of cricket is primarily recreational, featuring unconventional rules that deviate from traditional cricket norms. Box cricket not only fosters teamwork and the ability to perform under pressure but also contributes to overall health and well-being.",
    img : "Box Cricket",
    type : "Pre-Event",
    Coordinator : "Om Karwa",
    number : "8149752069"
  }
];

class BlogOne extends React.Component {

  Technoquiz = () =>{
    window.open("https://forms.gle/YPUEQWf45ySSFGC38");
  }

  PaperPresentation = () =>{
    window.open("https://forms.gle/jAdRjUZsDseKsdnZ6");
  }

  CVCruits = () =>{
    window.open("https://forms.gle/naMtZvkK1cTMB9RE9");
  }

  Softcon = () =>{
    window.open("https://forms.gle/HG4Fewen4EXWuNC87");
  }

  ETendering = () =>{
    window.open("https://forms.gle/mqdr3ynTJBGDJosS9");
  }

  BobTheBuilder = () =>{
    window.open("https://forms.gle/Td8d6x3pqTedcKNJ7");
  }

  TreasureHunt = () =>{
    window.open("https://forms.gle/4GSjHB8vKwD5G9VZ7");
  }

  BusinessPlan = () =>{
    window.open("https://docs.google.com/forms/d/16gAbHHbaDObbYsVBfq211C5J_pXSpAJQ5VTxYUJglJU/viewform");
  }

  RYLA = () =>{
    window.open("https://forms.gle/qhTi4G38t26tYoK5A");
  }

  BoxCricket = () =>{
    window.open("https://forms.gle/muKZHUv2sWnqoEs96");
  }

  
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>Events</h1>
            <span>Take a look at all our events</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Events</li>
            </ul>
          </div>
        </div>

        <section className="blog-area ptb-120 bg-image">
          <div className="container">
            <div className="row">
              {events.map((event) => (
                <div className="col-lg-4 col-md-6">
                  <div className="single-blog-post">
                    <div className="blog-image"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    >
                      <Link to="#">
                        <img
                          src={require(`../../assets/images/${event.img}.png`)}
                          alt="event"
                          height={150}
                          width={150}
                          style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        />
                      </Link>

                      <div className="post-tag">
                        <Link to="#">{event.type}</Link>
                      </div>
                    </div>

                    <div className="blog-post-content">
                      <h3>
                        <Link to="#">
                          {event.name}
                        </Link>
                      </h3>
                      <p>
                        {event.desc}
                      </p>

                      <p>
                        <b>Coordinator : {event.Coordinator}</b>
                      </p>
                      <p>
                        <b>Phone Number : {event.number}</b>
                      </p>
                      <Link to="#" className="read-more-btn"
                        onClick={ () => {
                          if(event.name === "TechnoQuiz")
                            this.Technoquiz();
                          else if(event.name === "Paper Presentation")
                            this.PaperPresentation();
                          else if(event.name === "CV Cruits")
                            this.CVCruits();
                          else if(event.name === "Softcon")
                            this.Softcon();
                          else if(event.name === "e-Tendering")
                            this.ETendering();
                          else if(event.name === "Bob The Builder")
                            this.BobTheBuilder();
                          else if(event.name === "Treasure Hunt")
                            this.TreasureHunt();
                          else if(event.name === "B’Plan In construction")
                            this.BusinessPlan();
                          else if(event.name === "Box Cricket")
                            this.BoxCricket();
                          else if(event.name === "R.Y.L.A")
                            this.RYLA();
                        }}
                      >
                            Register Now <i className="icofont-double-right"></i>
                        </Link>
                    </div>
                        
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default BlogOne;
