import React from 'react'
import MainBanner from '../AboutTwo/MainBanner';
import About from '../AboutTwo/About';
import WhyUs from '../AboutTwo/WhyUs';
import Partner from '../Common/Partner';
import Footer from '../Common/Footer';
import GoTop from '../Shared/GoTop';
import WhyUs2 from '../AboutTwo/WhyUs2';
 
class AboutTwo extends React.Component {
    render(){
        return (
            <React.Fragment>
                <MainBanner />
                <About />
                {/* <WhyUs /> */}
                <WhyUs2/>
                <Partner />
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default AboutTwo;