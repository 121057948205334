import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import OwlCarousel from "react-owl-carousel3";
import PlatinumSponsors from "../LaxButton/PlatinumSponsors";
import GoldSponsors from "../LaxButton/GoldSponsors";

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 3,
    },
    576: {
      items: 3,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};



const sponsers1 = [
  {
    img : "18"
  },

  {
    img : "19"
  },

  {
    img : "20"
  },

  {
    img : "21"
  },

  {
    img : "22"
  },

  {
    img : "23"
  },

  {
    img : "24"
  },

  {
    img : "25"
  }
];


class Partner extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section className="partner-area ptb-120">
        <div className="container">
          <div className="section-title">
            <span
              style={{
                color: "#27110E",
              }}
            >
              Check Who Makes This Event Possible!
            </span>
            <h2>
              Our Event <b>Sponsors</b>
            </h2>

            <div className="bar"></div>
          </div>

          <div className="row">
            <div className="col-lg-12">
            </div>
            <OwlCarousel
              className="platinum-partner-slides owl-carousel owl-theme"
              {...options}
            >
              {sponsers1.map((sponser) => (
                <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="/sponsors" target="_blank">
                    <img
                      src={require(`../../assets/images/${sponser.img}.png`)}
                      alt="Partner Logo"
                      style={{
                        height: "100px",
                      }}
                    />
                    <img
                      src={require(`../../assets/images/${sponser.img}.png`)}
                      alt="Partner Logo"
                      style={{
                        height: "100px",
                      }}
                    />
                  </Link>
                </div>
              </div>
              ))}
            </OwlCarousel>

            <div className="col-lg-12">
              <div className="border"></div>
            </div>

          </div>

        </div>
      </section>
    );
  }
}

export default Partner;
