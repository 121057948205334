import React from 'react';
import MainBanner from '../HomeDefault/MainBanner';
import About from '../HomeDefault/About';
import WhyUs from '../Common/WhyUs';
import GoTop from '../Shared/GoTop';
import Footer from '../Common/Footer';
import FunFact from '../Common/FunFact';
import lax from 'lax.js';
import Partner from '../Common/Partner';

class HomeDefault extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (
            <React.Fragment>
                <MainBanner />
                <About />
                <WhyUs />
                <FunFact />
                <Partner />
                <Footer />

                {/* Back Top top */}
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default HomeDefault;