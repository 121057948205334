import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/responsive.css";

class WhyUs2 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      cssEase: "linear",
    };

    const images = [
      {
        src: "https://i.imgur.com/MxqgcWz.jpg",
        caption: "Our Vision",
        desc: " To provide an opportunity for the accomplished and aspiring civil engineers to indulge, exchange ideas and foster new integrated approaches and build a strong network of determined professionals.",
      },
      {
        src: "https://i.imgur.com/qjLDiyc.jpg",
        caption: "Our Mission",
        desc: "To aggrandize the changing methods in the construction industry brought about by the evolution of technology whilst preserving the cultural heritage of India in today’s digital era.",
      },
      {
        src: "https://i.imgur.com/2GGSGiM.jpg",
        caption: "Know our Theme",
        desc: " Here at Nirmitee, we strive to learn from the great architects of Ancient India, in the hope to follow their footsteps and use the present technology to achieve distinguished success in modern architecture, hence the theme ‘Sthapati to Modernity.’",
      },
    ];

    return (
      <div
        style={{
          margin: "2rem",
        }}
      >
        <Slider
          {...settings}
          style={{
            height: "600px",
          }}
        >
          {images.map((image, index) => (
            <section className="about-area-two ptb-120">
              <div className="container">
                <div className="row h-100 align-items-center">
                  <div className="col-lg-6">
                    <div className="about-image">
                      <img
                        //src={require("../../assets/images/about3.jpg")}
                        src={image.src}
                        className="about-img1"
                        alt="about"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="about-content">
                      <span>Join The Event</span>
                      <h2>{image.caption}</h2>

                      <p>{image.desc}</p>

                      <Link to="/events" className="btn btn-primary">
                        Register Now!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </Slider>
      </div>
    );
  }
}

export default WhyUs2;
